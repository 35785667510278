/* 公共函数 */


// 数字超过一万，以万为单位返回数字
export function getNumbers(num_) {
  if (num_ >= 10000) {
    // 千位下浮动,如：1.21万 -> 1.2万
    // return parseInt(num_/10000*10)/10+'万';
    // 千位上浮动,如：1.21万 -> 1.3万
    return Math.ceil(num_ / 1000) / 10 + 'w'
  } else {
    return num_
  }
}

// 学习进度百分比
export function percentage(total_, viewed_) {
  let percent = total_ === 0 ? 0 : (viewed_ / total_) * 100
  if (percent > 100) { // 容错处理
    percent = 100
  }

  // 保留小数点
  let y = String(percent).indexOf(".") + 1;//获取小数点的位置
  let count = String(percent).length - y;//获取小数点后的个数
  if (count > 2) {
    return percent.toFixed(2)
  } else {
    return percent
  }

}

// 文字超出增加...
export function cutStr(value, end) {
  if (value.length > end) {
    return value.substr(0, end) + '...'
  } else {
    return value
  }
}

// 图片地址前缀
export function prefixSrc(value, type) {
  if (value == 1) {
    return process.env.VUE_APP_IMG_API + "/common/team.png"
  }
  if (value) {
    if (value.indexOf('http') == -1) {
      if (type == 1) {
        return 'https://img.ke.yiihuu.com/'.concat(value)
      } else {
        return process.env.VUE_APP_IMG_API + '/'.concat(value)
      }
    }
    if (value.indexOf('api/uploads/') != -1 && value.indexOf('http') == -1) {
      return process.env.BASE_API + '/'.concat(value)
    } else {
      return value
    }

  }
}

// 头像
export function avatarSrc(value) {
  if (value) {
    if (value.indexOf('http') == -1) {
      return process.env.VUE_APP_IMG_API + '/'.concat(value)
    }
    if (value.indexOf('api/uploads/') != -1 && value.indexOf('http') == -1) {
      return process.env.BASE_API + '/'.concat(value)
    } else {
      return value
    }

  } else {
    return process.env.VUE_APP_IMG_API + "/common/student_boy.png"
  }
}

// 首页图片地址
export function homeSrc(value, type) {
  switch (type) {
    case 1:
      return `https://img12.yiihuu.com/upimg/manage/${value}`
    case 2:
      return `https://img12.yiihuu.com/upimg/courses/${value}`
    default:
      return `https://img12.yiihuu.com/upimg/album/${value}`
  }

}

// 日期转天数
export function leftDay(time) {
  var EndTime = new Date(time);
  var NowTime = new Date();
  var t = EndTime.getTime() - NowTime.getTime();
  var res =
    Math.ceil(t / 1000 / 60 / 60 / 24) > 0
      ? Math.ceil(t / 1000 / 60 / 60 / 24)
      : 0;
  return res;
}