
<template>
  <div class="dialog">
    <el-dialog
      :visible.sync="showRegister"
      append-to-body
      modal-append-to-body
      width="710px"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="loginBody">
        <div class="imgWrap">
          <p class="title1">免费学设计的宝藏资源库</p>
          <p class="txt">涵盖9大设计领域</p>
          <p class="txt">30000+课时教程免费学习</p>
          <p class="txt">55+设计软件零基础入门至精通</p>
          <p class="txt">教程案例工程文件一键下载</p>
          <p class="txt">在线设计工具自带百万设计素材</p>
          <p class="txt">有奖创作活动检验学习成果</p>
          <p class="txt">学习进度云端同步</p>
          <p class="title2">更多设计学习干货，登录即可获取</p>
        </div>
        <div class="loginInfo">
          <img class="logo" src="@/assets/images/logo6.png" alt="" />
          <div class="tab">
            <div class="tabItem" :class="{ active: countlogin }">账号注册</div>
          </div>
          <div class="tabContent">
            <!-- 账号登录 -->
            <div v-show="countlogin" class="countWrap">
              <el-form
                :model="countForm"
                :rules="countRules"
                ref="countForm"
                class="countForm"
              >
                <el-form-item prop="account">
                  <div class="itemform">
                    <span class="label">
                      <i class="el-icon-user"></i>
                    </span>
                    <el-input
                      type="input"
                      v-model="countForm.account"
                      placeholder="请输入手机号"
                      maxlength="11"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="imgCode">
                  <div class="itemform">
                    <span class="label labelPwd">
                      <i class="el-icon-s-claim"></i>
                    </span>
                    <el-input
                      type="input"
                      class="txCode"
                      v-model="countForm.imgCode"
                      placeholder="请输入图像验证码"
                      maxlength="6"
                      @keyup.native.enter="checkLogin"
                    ></el-input>
                    <div class="sendCode" @click="changeCode" id="imgCode">
                      <img src="/jsapi/verify-code-img.php" />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item prop="code">
                  <div class="itemform yzform">
                    <span class="label labelPwd">
                      <i class="el-icon-key"></i>
                    </span>
                    <el-input
                      type="input"
                      class="yzCode"
                      v-model="countForm.code"
                      placeholder="请输入验证码"
                      maxlength="6"
                      @keyup.native.enter="checkLogin"
                    ></el-input>
                  </div>
                  <el-button @click="sendCode" class="yzCodeBtn" v-if="isSend"
                    >发送验证码</el-button
                  >
                  <el-button class="yzCodeBtn" v-else
                    >{{ backTime }}秒</el-button
                  >
                </el-form-item>
                <el-form-item prop="password">
                  <div class="itemform">
                    <span class="label labelPwd">
                      <i class="el-icon-lock"></i>
                    </span>
                    <el-input
                      type="password"
                      class="user_password"
                      v-model="countForm.password"
                      placeholder="8~16位字母、数字或字符(建议至少两种)"
                      @keyup.native.enter="registerCount"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item prop="repassword">
                  <div class="itemform">
                    <span class="label labelPwd">
                      <i class="el-icon-lock"></i>
                    </span>
                    <el-input
                      style="flex: 1;"
                      type="password"
                      v-model="countForm.repassword"
                      placeholder="请再次输入密码"
                      @keyup.native.enter="registerCount"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-form>
              <p
                class="login-aggre"
                @click="agreement"
                style="margin-top: 10px"
              >
                同意翼狐设计学习库<a
                  class="server-title"
                  href="https://www.yiihuu.com/appapi/libapi/include/privacy.php"
                  target="_blank"
                  >《服务协议》</a
                >和<a
                  class="server-title"
                  href="https://www.yiihuu.com/appapi/libapi/include/privacy_v2.php"
                  target="_blank"
                  >《隐私政策》</a
                >
              </p>
              <div class="btns">
                <el-button
                  @click="registerCount"
                  class="loginbtn"
                  :loading="showLoading"
                  >注册</el-button
                >
              </div>
              <div class="forgetWrap">
                <a style="visibility: hidden">忘记密码</a>
                <a href="javascript:;" @click="toLogin">直接登录 &gt;&gt;</a>
              </div>
            </div>
            <!-- 读者登录 -->
            <div v-show="!countlogin" class="readWrap"></div>
          </div>
        </div>
      </div>
      <!-- 登录弹出框 -->
      <loginDialog ref="loginDialog"></loginDialog>
    </el-dialog>
  </div>
</template>

<script>
import { getToken, getNick } from "@/utils/auth";
import { register } from "@/api/home.js";
export default {
  name: "registerDialog",
  data() {
    return {
      showRegister: false,
      countlogin: true,
      countForm: {
        method: "yiihuu.register",
        domainname: "//lib.yiihuu.com",
        system_type: "pc_lib",
        account: "",
        password: "",
        repassword: "",
        imgCode: "",
        code: "",
      },
      countRules: {
        account: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        repassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
        imgCode: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      showLoading: false,
      isSend: true,
      backTime: 60,
      isSend: true,
      mycode: {
        method: "yiihuu.register-sms-send",
        rec_num: "",
        verify_image_code: "",
      },
    };
  },
  created() {},
  methods: {
    showRegisterDialog() {
      this.showRegister = true;
    },
    handleClose() {
      this.showRegister = false;
    },
    // 账号登录
    registerCount() {
      this.$refs["countForm"].validate((valid) => {
        if (valid) {
          this.showLoading = true;
          register(this.countForm)
            .then((res) => {
              if (res.error == 0) {
                setTimeout(() => {
                  this.toLogin();
                  this.showLoading = false;
                }, 1000);
              } else {
                this.showLoading = false;
                this.$message.warning(res.msg);
              }
            })
            .catch((err) => {
              this.showLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 倒计时
    timeBack() {
      this.backTimeId = setTimeout(() => {
        this.backTime = this.backTime - 1;
        if (this.backTime <= 0) {
          this.backTime = 60;
          this.isSend = true;
        }
        this.timeBack();
      }, 1000);
    },
    // 获取验证码
    async sendCode() {
      const { account, imgCode } = this.countForm;
      if (account && imgCode) {
        this.isSend = false;
        this.timeBack();
        this.mycode.rec_num = account;
        this.mycode.verify_image_code = imgCode;
        let res = await register(this.mycode);
        if (res.error != 0) {
          clearTimeout(this.backTimeId);
          this.isSend = true;
          this.backTime = 60;
          this.$message.warning(res.msg);
        }
      } else if (!account) {
        this.$message({
          message: "请输入手机号码！",
          type: "warning",
        });
      } else {
        this.$message({
          message: "请输入图片验证码！",
          type: "warning",
        });
      }
    },
    changeCode() {
      let imgCode = document.getElementById("imgCode");
      imgCode.innerHTML = '<img src="/jsapi/verify-code-img.php" />';
    },
    toLogin() {
      this.$refs.loginDialog.showLoginDialog();
      this.handleClose();
    },
    agreement() {
      this.$message({
        message: "您需要同意翼狐设计学习库网站服务协议才可登录",
        type: "warning",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginBody {
  width: 710px;
  display: flex;
  .imgWrap {
    width: 297px;
    height: 550px;
    color: #fff;
    background: url("../../assets/images/login/new_left_bg.png") no-repeat
      center/100%;
    padding-left: 24px;
    .title1 {
      font-size: 18px;
      margin-top: 72px;
      margin-bottom: 28px;
      letter-spacing: 1px;
    }
    .txt {
      position: relative;
      padding-left: 30px;
      margin-bottom: 20px;
      &::before {
        content: "";
        position: absolute;
        display: inline-block;
        top: 3px;
        left: 0;
        width: 15px;
        height: 15px;
        background: url("../../assets/images/login/ico_right.png") no-repeat
          center/100%;
      }
    }
    .title2 {
      padding-top: 4px;
      letter-spacing: 2px;
      font-size: 15px;
    }
  }
  .loginInfo {
    width: 413px;
    height: 550px;
    background-color: #fff;
    text-align: center;
    background: url("../../assets/images/login/right_bg.png") no-repeat bottom
      center/100%;
    .logo {
      display: inline-block;
      width: 226px;
      margin: 24px auto 10px;
    }
    .tab {
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      align-items: center;
      .tabItem {
        height: 30px;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
        width: 100%;
        text-align: center;
        line-height: 30px;
      }
    }
    .tabContent {
      .countWrap {
        padding: 0 67px;

        .countForm {
          .yzform {
            width: 152px;
            ::v-deep .el-input {
              width: 102px !important;
            }
          }
          .itemform {
            display: flex;
            align-items: center;

            border: 1px solid #eee;
            border-radius: 5px;
            .label {
              width: 50px;
            }
            ::v-deep .el-input {
              width: 168px;
              input {
                padding: 0;
                border: 0;
              }
            }
          }
        }
        .btns {
          width: 100%;
          text-align: center;
          ::v-deep .el-button {
            width: 100%;
            height: 46px;
            line-height: 46px;
            padding: 0;
            background: linear-gradient(90deg, #1adc8a 0%, #23b4a0 100%);
            border-radius: 10px;
            color: #fff;
            letter-spacing: 2px;
            margin-top: 18px;
            cursor: pointer;
            transition: all 0.3s;
          }
        }
        .forgetWrap {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
          a {
            text-decoration: none;
            color: #22b69e;
            cursor: pointer;
            font-size: 14px;
          }
        }
      }
      .readWrap {
      }
    }
  }

  .login-aggre {
    width: 288px;
    margin: 0 auto;
    padding-left: 22px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.5px;
    position: relative;
  }

  .login-aggre::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url("../../assets/images/ico_agger.png") no-repeat center/74%
      #1ada8b;
    border-radius: 3px;
  }
  .server-title {
    color: #22b59f;
  }
}
::v-deep .el-dialog {
  border-radius: 15px;
  overflow: hidden;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 0;
  border-radius: 10px;
}
::v-deep .el-input {
  width: 370px;
  box-sizing: border-box;
  input {
    border: 0;
  }
  &.txCode {
    padding-right: 96px;
    flex: 1;
  }
  &.yzCode {
    input {
      padding-right: 125px;
    }
  }
}

::v-deep .sendCode {
  position: absolute;
  padding: 0;
  right: 10px;
  top: 50%;
  transform: translate(0, -55%);
  width: 78px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  color: #ffffff;
  border: none;
  img {
    width: 100%;
    height: 100%;
  }
}
::v-deep .yzCodeBtn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 120px;
  line-height: 100%;
  color: #c1c4cc;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #eee;
  transition: all 0.3s;
}
.user_password {
  flex: 1;
  ::v-deep input::placeholder {
    font-size: 12px !important;
  }
}
</style>
