import Cookies from 'js-cookie'

const TokenKey = 'yiihuu_sso'

const TokenNick = "nick"
export function getToken() {

  return Cookies.get(TokenKey)
  return "71f905893a3869ae70054e543dad63b6fd4a93cd"
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


export function getNick() {
  return Cookies.get(TokenNick)
  return "用户名"
}

export function setNick(name) {
  return Cookies.set(TokenNick, name)
}

export function removeNick() {
  return Cookies.remove(TokenNick)
}

