
<template>
  <div class="dialog">
    <!-- :close-on-click-modal="false" -->
    <el-dialog
      :visible.sync="showLogin"
      append-to-body
      modal-append-to-body
      lock-scroll
      width="680px"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="loginBody">
        <div class="imgWrap">
          <p class="title1">免费学设计的宝藏资源库</p>
          <!-- <p class="txt">涵盖9大设计领域</p>
          <p class="txt">30000+课时教程免费学习</p>
          <p class="txt">55+设计软件零基础入门至精通</p>
          <p class="txt">30000+课时教程免费学习</p>
          <p class="txt">教程案例工程文件一键下载</p>
          <p class="txt">在线设计工具自带百万设计素材</p>
          <p class="txt">有奖创作活动检验学习成果</p>
          <p class="txt">学习进度云端同步</p> -->
          <p class="title2">更多设计学习干货</p>
          <p class="title2">登录即可获取</p>
        </div>
        <div class="loginInfo">
          <img class="logo" src="@/assets/images/logo6.png" alt="" />
          <div class="df jc-c" style="margin-top: 10px; margin-bottom: 15px">
            <div
              class="login-title loginBtn"
              :class="isWechat ? '' : 'active'"
              @click="handleLoginBox(false)"
            >
              账号密码登录
            </div>
            <span class="login-line">|</span>
            <div
              class="wechat-login-title wechatLoginBtn"
              :class="isWechat ? 'active' : ''"
              @click="handleLoginBox(true)"
            >
              微信登录
            </div>
          </div>
          <div class="tabContent">
            <!-- 账号登录 -->
            <div v-show="!isWechat" class="countWrap">
              <el-form
                :model="countForm"
                :rules="countRules"
                ref="countForm"
                class="countForm"
              >
                <el-form-item
                  prop="account"
                  style="width: 288px; margin: 0 auto 22px"
                >
                  <div class="itemform">
                    <span class="label">
                      <i class="el-icon-user"></i>
                    </span>
                    <el-input
                      type="input"
                      v-model="countForm.account"
                      placeholder="请输入邮箱/手机号"
                    ></el-input>
                  </div>
                </el-form-item>
                <el-form-item
                  prop="password"
                  style="width: 288px; margin: 0 auto 22px"
                >
                  <div class="itemform">
                    <span class="label labelPwd">
                      <i class="el-icon-lock"></i>
                    </span>
                    <el-input
                      type="password"
                      v-model="countForm.password"
                      placeholder="请输入密码"
                      @keyup.native.enter="loginCount"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-form>

              <a
                class="forget_paw"
                href="https://passport.yiihuu.com/ossdm/find_psw_phone.html"
                target="_blank"
                >忘记密码?</a
              >

              <p
                class="login-aggre f-pr"
                @click="agreement"
                style="margin-top: 10px"
              >
                同意翼狐设计学习库<a
                  class="server-title"
                  href="https://www.yiihuu.com/appapi/libapi/include/privacy.php"
                  target="_blank"
                  >《服务协议》</a
                >和<a
                  class="server-title"
                  href="https://www.yiihuu.com/appapi/libapi/include/privacy_v2.php"
                  target="_blank"
                  >《隐私政策》</a
                >
              </p>

              <div class="btns">
                <el-button
                  @click="loginCount"
                  class="loginbtn"
                  :loading="showLoading"
                  >登录</el-button
                >
              </div>
            </div>

            <div v-show="isWechat" class="wechat-login-box">
              <img class="wechat-ewm" id="wx_code_src" :src="ewmImg" alt="" />
              <div class="wx_loading" v-show="isLoading">
                <div class="wx_flex_w1">
                  <img
                    src="../../assets/images/wx_loadIcon.png"
                    class="wx_loadimg"
                    alt=""
                  />
                  <p>二维码已失效</p>
                  <a
                    href="javascript:void(0)"
                    class="wx_loadan"
                    @click="getCode()"
                    >点击刷新</a
                  >
                </div>
              </div>
              <div class="wechat-login-text">微信扫码关注登录</div>
            </div>

            <div class="forgetWrap">
              <div>
                <a
                  class="qq-login"
                  href="javascript:void(0)"
                  @click="loginbtnqq()"
                  title="QQ登录"
                ></a>
              </div>
              <a href="javascript:;" @click="toRegister">注册账号>></a>
            </div>
            <!-- 读者登录 -->
            <div v-show="!countlogin" class="readWrap"></div>
          </div>
        </div>
      </div>
      <!-- 注册弹出框 -->
      <registerDialog ref="registerDialog"></registerDialog>
    </el-dialog>
  </div>
</template>

<script>
import Bus from "@/utils/bus";
import { getToken, getNick } from "@/utils/auth";
import { login, getPersonalInfo } from "@/api/home.js";
export default {
  name: "loginDialog",
  data() {
    return {
      showLogin: false,
      countlogin: true,
      countForm: {
        method: "yiihuu.login",
        system_type: "pc_lib",
        account: "",
        password: "",
      },
      countRules: {
        account: [
          { required: true, message: "请输入邮箱/手机号", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      showLoading: false,
      isWechat: false,
      ewmImg: "",
      thisUrl: "",
      wx_user_ws: 0,
      countNum: 1,
      isLoading: false,
      timerB: null,
    };
  },
  created() {
    this.init();
  },
  computed: {},
  components: {},
  methods: {
    init() {
      this.thisUrl = window.location.href;
      this.wx_user_ws =
        Math.round(Math.random() * 15 + 1) + Date.parse(new Date()); //1-15随机数+当前时间戳
    },
    // 微信二维码倒计时
    countdown() {
      var this_ = this;
      clearInterval(timer);
      var timer = setInterval(function () {
        this_.countNum++;
        if (this_.countNum === 60) {
          clearInterval(timer);
          this_.clearTimer();
          this_.isLoading = true;
          this_.countNum = 1;
        }
      }, 2000);
    },
    // 轮询监听是否微信登录
    setTimer() {
      var this_ = this;
      this.timerB = setInterval(function () {
        var params = {
          wxloginkey: this_.wx_user_ws,
        };
        this_
          .$axios({
            method: "get",
            url: "https://www.yiihuu.com/app/weixin_lib/ajax_qrcode_login.php",
            params,
          })
          .then((resData) => {
            // console.log(resData)
            if (resData.data.code == 0) {
              window.location.href =
                "https://passport.yiihuu.com/third-party-oauth/callback.php?oauth_uid=" +
                resData.data.data.openid +
                "&unionid=" +
                resData.data.data.unionid +
                "&nick=" +
                resData.data.data.nickname +
                "&avatar=" +
                resData.data.data.avatar +
                "&access_token=&expires_in=&refresh_token=&oauth_type=wx&system_type=pc_lib&target_uri=" +
                this_.thisUrl;
            }
          });
      }, 3000);
    },
    // 关闭轮询函数
    clearTimer() {
      clearInterval(this.timerB);
    },
    agreement() {
      this.$message({
        message: "您需要同意翼狐设计学习库网站服务协议才可登录",
        type: "warning",
      });
    },
    handleClose() {
      // this.$emit("close", false);
      this.showLogin = false;
    },
    showLoginDialog() {
      this.showLogin = true;
    },
    loginMethods(_is) {
      this.countlogin = _is;
    },
    toRegister() {
      this.$refs.registerDialog.showRegisterDialog();
      this.handleClose();
    },
    // 获取wx二维码
    getCode() {
      var params = {
        wxloginkey: this.wx_user_ws,
      };
      this.$axios({
        method: "get",
        url: "https://www.yiihuu.com/app/erweima2_lib/index.php",
        params,
      }).then((resData) => {
        console.log("二维码图片：" + resData.data);
        this.ewmImg = resData.data;
        this.isLoading = false;
        this.setTimer();
      });
    },
    // 账号登录
    loginCount() {
      this.$refs["countForm"].validate((valid) => {
        if (valid) {
          this.showLoading = true;
          login(this.countForm)
            .then((res) => {
              if (res.error == 0) {
                this.showLoading = false;
                location.reload();
              } else {
                this.showLoading = false;
                this.$message.warning(res.msg);
              }
            })
            .catch((err) => {
              this.showLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleLoginBox(bool_) {
      // 选择登录方式
      this.isWechat = bool_;
      if (this.isWechat) {
        this.getCode();

        this.countdown();
      } else {
        this.clearTimer();
      }
    },
    // QQ登录
    loginbtnqq() {
      window.location.href =
        "//www.yiihuu.com/app/tengxun/oauth/redirect_to_login.php?target_uri=" +
        this.thisUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.df {
  display: flex;
}
.jc-c {
  justify-content: center;
}
.f-pr {
  position: relative;
}
.loginBody {
  width: 680px;
  height: 452px;
  display: flex;
  font-weight: 400;
  .imgWrap {
    width: 285px;
    height: 452px;
    color: #fff;
    background: url("../../assets/images/left_bg.png") no-repeat center/100%;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    text-align: center;
    .title1 {
      font-size: 18px;
      margin-top: 70px;
      margin-bottom: 28px;
      letter-spacing: 1px;
    }
    .txt {
      position: relative;
      padding-left: 30px;
      margin-bottom: 28px;
      height: 22px;
    }
    .txt::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 2px;
      left: 0;
      width: 15px;
      height: 15px;
      background: url("../../assets/images/ico_right.png") no-repeat center/100%;
    }
    .title2 {
      padding-top: 18px;
      letter-spacing: 2px;
    }
  }
  .loginInfo {
    // width: 370px;
    // height: 366px;
    flex: 1;
    background-color: #fff;
    text-align: center;
    .logo {
      display: inline-block;
      width: 226px;
      margin: 24px auto 10px;
    }
    .login-title {
      cursor: pointer;
      font-size: 14px;
      line-height: 30px;
    }
    .login-title.active {
      border-bottom: 2px solid #23b4a0;
      color: #23b4a0;
    }
    .wechat-login-title.active {
      border-bottom: 2px solid #23b4a0;
      color: #23b4a0;
    }
    .login-line {
      color: #999;
      margin: 0 10px;
      line-height: 30px;
    }
    .wechat-login-title,
    .wechat-login-text {
      cursor: pointer;
      position: relative;
      padding-left: 27px;
      line-height: 30px;
    }
    .wechat-login-title::before,
    .wechat-login-text::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 7px;
      left: 0;
      width: 19px;
      height: 16px;
      background: url("../../assets/images/ico_wechat.png") no-repeat
        center/100%;
    }
    .wechat-login-text::before {
      left: 130px;
    }
    .forget_paw {
      display: inline-block;
      width: 288px;
      text-align: right;
      color: #444;
      text-decoration: none;
    }

    .login-aggre {
      width: 288px;
      margin: 0 auto;
      padding-left: 22px;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.5px;
    }

    .login-aggre::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      background: url("../../assets/images/ico_agger.png") no-repeat center/74%
        #1ada8b;
      border-radius: 3px;
    }

    .server-title {
      color: #22b59f;
    }

    .loginbtn {
      width: 288px;
      height: 46px;
      // line-height: 46px;
      background: linear-gradient(90deg, #1adc8a 0%, #23b4a0 100%);
      border-radius: 10px;
      color: #fff;
      letter-spacing: 2px;
      margin-top: 18px;
      cursor: pointer;
    }

    .tab {
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      align-items: center;
      .tabItem {
        height: 44px;
        // font-size: 16px;
        // font-weight: 400;
        font-size: 18px;
        font-weight: 700;
        color: #333333;
        cursor: pointer;
        // width: 50%;
        width: 100%;
        text-align: center;
        line-height: 44px;
        background-color: #f0f0f0;
        &.active {
          background-color: #fff;
        }
      }
    }
    .tabContent {
      .countWrap {
        .countForm {
          .itemform {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            border: 1px solid #eee;
            border-radius: 5px;
            .label {
              width: 50px;
            }
            ::v-deep .el-input {
              width: 168px;
              input {
                padding: 0;
                border: 0;
              }
            }
          }
        }
      }
      .forgetWrap {
        width: 288px;
        display: flex;
        justify-content: space-between;
        margin: 12px auto 0;
        .qq-login {
          display: inline-block;
          width: 26px;
          height: 25px;
          background: url(../../assets/images/ico_qq.png) no-repeat center/100%;
        }
        a {
          text-decoration: none;
          color: #22b69e;
          font-size: 14px;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  border-radius: 15px;
  overflow: hidden;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 0;
  border-radius: 10px;
}

.wechat-login-box {
  border-bottom: 1px solid #ddd;
  position: relative;
  .wechat-ewm {
    display: inline-block;
    width: 156px;
    height: 156px;
  }
  .wx_loading {
    position: absolute;
    top: 0;
    left: 120px;
    background-color: rgba(0, 0, 0, 0.5);
    display: inline-block;
    width: 156px;
    height: 156px;
  }
  .wx_loading img {
    display: inline-block;
    width: 28px;
    height: 24px;
    margin-top: 32px;
  }
  .wx_loading p {
    line-height: 14px;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 1px;
    margin: 20px 0 10px;
  }
  .wx_loading a {
    width: 76px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    letter-spacing: 1px;
    color: #333333;
    background-color: rgba(255, 255, 255, 0.88);
    border-radius: 4px;
    transition: all ease 0.4s;
    display: block;
    margin: 0 auto;
    text-decoration: none;
  }
  .wechat-login-text {
    margin: 10px 0 14px;
    cursor: pointer;
    position: relative;
    padding-left: 27px;
    line-height: 30px;
  }
  // .wechat-login-text::before{
  //   content: '';
  //   position: absolute;
  //   display: inline-block;
  //   top: 7px;
  //   left: 76px;
  //   width: 19px;
  //   height: 16px;
  //   background: url(../../assets/images/ico_wechat.png) no-repeat center/100%;
  // }
}
</style>
