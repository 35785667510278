<template>
  <div class="header" :style="isFixed ? 'position: fixed;' : ''">
    <div class="welcome_father">
      <div class="welcome">翼狐设计学习库欢迎您!</div>
    </div>

    <div class="header_main">
      <a href="https://lib.yiihuu.com/" class="dib line-height1 fl logo-box">
        <img src="../assets/images/header/logo2.png" alt="" class="ico" />
      </a>
      <ul class="clearfix nav-box dib">
        <li><a href="https://lib.yiihuu.com/">首页</a></li>
        <li><a href="https://lib.yiihuu.com/list/">视频教程</a></li>
        <li>
          <a href="https://lib.yiihuu.com/learn_path/" class="pr"
            >学习路径
            <img
              src="../assets/images/header/hot.png"
              style="
                position: absolute;
                top: -13px;
                right: -8px;
                width: 30px;
                height: 16px;
              "
              alt=""
              class="hot-icon-box"
            />
          </a>
        </li>
        <li class="pr">
          <router-link to="/resource" class="nick"> 资源下载 </router-link>
        </li>
        <!-- <li><a href="https://lib.yiihuu.com/resource/">资源下载</a></li> -->
        <li class="pr">
          <span href="" @mouseover="showTwjc(true)" @mouseout="showTwjc(false)"
            >图文资讯</span
          >
          <div
            class="sub-nav"
            @mouseover="showTwjc(true)"
            @mouseout="showTwjc(false)"
            v-show="twjc"
          >
            <div><a href="https://lib.yiihuu.com/twjc/">图文教程</a></div>
            <div><a href="https://lib.yiihuu.com/news/">行业资讯</a></div>
          </div>
        </li>
        <li><a href="https://lib.yiihuu.com/live/" class="live-link"></a></li>
        <li class="pr">
          <span href="" @mouseover="showDesign(true)" @mouseout="showDesign(false)">设计中心</span>
          <div
            class="sub-nav"
            @mouseover="showDesign(true)" 
            @mouseout="showDesign(false)"
            v-show="design"
          >
            <div><a href="https://lib.yiihuu.com/guide.php">设计导航</a></div>
            <div><a href="https://lib.yiihuu.com/sc/">在线设计</a></div>
            <div><a href="https://lib.yiihuu.com/lib/#/design/matting">在线抠图</a></div>
            <div><a href="https://lib.yiihuu.com/lib/#/design/picture">图片编辑</a></div>
          </div>
        </li>

        <li class="pr">
          <span href="" @mouseover="showCkzx(true)" @mouseout="showCkzx(false)">创客中心</span>
          <div
            class="sub-nav"
            @mouseover="showCkzx(true)" 
            @mouseout="showCkzx(false)"
            v-show="ckzx"
          >
            <div><a href="https://lib.yiihuu.com/idear/">创意分享</a></div>
            <div><a href="https://lib.yiihuu.com/cooperation/">创意变现</a></div>
          </div>
        </li>
        <!-- <li class="pr">
          <router-link to="/works/" class="nick"> 作品广场 </router-link>
        </li> -->
        <li><a href="https://lib.yiihuu.com/activity/">比赛活动</a></li>
      </ul>
      <div class="fr login-box" v-if="token && nick">
        <a
          href="https://lib.yiihuu.com/personal/personal_info.php"
          class="nick"
          >{{ nick }}</a
        >
        <span>&nbsp;|&nbsp;</span>
        <a href="javascript:;" @click="logout">退出</a>
      </div>
      <div class="fr login-box" v-else>
        <a href="javascript:;" class="nick" @click="showLogin">登录</a>
        <span>&nbsp;|&nbsp;</span>
        <a @click="showRegister" href="javascript:;">注册</a>
      </div>
      <div class="fr pr clearfix input">
        <!-- 只能使用form表单发请求的形式指定编码 -->
        <form method="get" action="https://lib.yiihuu.com/list/" accept-charset="gbk">
            <div class="">
                <input class="input1" type="text" name="q" placeholder="搜索视频" value="">
                <input type="hidden" name="web_flag" value="yiihuu">
                <input type="submit" class="submit" value="">
            </div>
        </form>
        <!-- <input
          type="text"
          placeholder="搜索视频"
          class="search"
          v-model="searchData"
        />
        <button class="submit" @click="handleSearch"></button> -->
      </div>
    </div>
    <!-- 登录弹出框 -->
    <loginDialog ref="loginDialog"></loginDialog>
    <!-- 注册弹出框 -->
    <registerDialog ref="registerDialog"></registerDialog>
  </div>
</template>

<script>
import { logout } from "@/api/home.js";
import { getToken, getNick } from "@/utils/auth.js";
export default {
  name: "Header",
  components: {},
  props: {
    isFixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      twjc: false,
      ckzx: false,
      design: false,
      searchData: "",
      token: getToken(),
      nick: getNick(),
    };
  },
  created() {},
  computed: {},

  methods: {
    showTwjc(val) {
      this.twjc = val;
    },
    showCkzx(val) {
      this.ckzx = val;
    },
    showDesign(val){
      this.design = val;
    },
    handleSearch() {
      
      // let data = "https://lib.yiihuu.com/list/?q=" + this.searchData;
      let data = encodeURIComponent(this.searchData);
      // let url = encodeURI(data)
      let url = "https://lib.yiihuu.com/list/?q=" + data
      console.log(url)
      // console.log(data)
      // window.location.href = url;
    },
    logout() {
      logout()
        .then((res) => {
          location.reload();
        })
        .catch((err) => {
          location.reload();
        });
    },
    showLogin() {
      this.$refs.loginDialog.showLoginDialog();
    },
    showRegister() {
      this.$refs.registerDialog.showRegisterDialog();
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.input1{
  float: left;
  border-radius: 16px;
  width: 100%;
  height: 36px;
  outline: none;
  padding: 10px 0 8px 15px;
  background: none;
  border: none;
  box-sizing: border-box;
}

input[type="hidden" i] {
  display: none!important;
}

.header {
  width: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 2500;
  box-shadow: 0px 3px 6px rgb(0, 0, 0, 0.05);
  .welcome_father {
    width: 100%;
    background-color: #22b79e;
    .welcome {
      width: 1380px;
      margin: 0 auto;
      height: 31px;
      line-height: 30px;
      color: white;
      font-size: 14px;
    }
  }
}

.header .header_main {
  width: 1380px;
  height: 76px;
  margin: 0 auto;
  box-sizing: border-box;
}
.nav-box li {
  float: left;
  font-size: 14px;
  line-height: 76px;
  height: 76px;
  margin-left: 19px;
  padding-top: 4px;
  box-sizing: border-box;
}
.nav-box li a {
  color: #666666;
}
.nav-box li span {
  color: #666;
  cursor: default;
  height: 72px;
  display: inline-block;
  line-height: 75px;
  font-size: 14px;
}
.nav-box li a:hover {
  color: #05887d;
}
.sub-nav {
  z-index: 9999;
  background-color: #fff;
  text-align: center;
  position: absolute;
  width: 94px;
  top: 76px;
  left: -19px;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}
.sub-nav a {
  display: block;
  height: 50px;
  line-height: 50px;
}
.sub-nav div a:hover {
  background: linear-gradient(90deg, #1ADC8A 0%, #23B4A0 100%);
  color: #fff;
}

.header .logo-box {
  margin-top: 14px;
}
.login-box {
  line-height: 76px;
}
.login-box a,
.login-box span {
  color: #868686;
  font: 12px/1.5 "Microsoft YaHei", \5b8b\4f53;
  vertical-align: middle;
}
.nick {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.input {
  float: right;
  width: 130px;
  height: 36px;
  border-radius: 18px;
  background-color: #f3f3f3;
  position: relative;
  margin-top: 20px;
  margin-right: 14px;
  // float: left;
  // border-radius: 16px;
  // width: 100%;
  // height: 36px;
  // outline: none;
  // padding: 10px 0 8px 15px;
  // background: none;
  // border: none;
  // box-sizing: border-box;
}
.search {
  float: left;
  border-radius: 16px;
  width: 100%;
  height: 36px;
  outline: none;
  padding: 10px 0 8px 15px;
  background-color: #f2f2f2;
  border: none;
  box-sizing: border-box;
  font-size: 12px;
}

.submit {
  background: url("../assets/images/header/search_icon.png") no-repeat;
  width: 16px;
  height: 18px;
  margin: 9px 16px;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
}
@media screen and (max-width: 1500px) and (min-width: 270px) {
  .header {
    .header_main {
      width: 1100px;
    }
    .welcome {
      width: 1100px !important;
    }
  }
}
.live-link {
  display: inline-block;
  position: relative;
  width: 58px;
  height: 100%;
  background: url("../assets/images/header/yiihuuLive.png") 0 15px no-repeat;
  background-size: 100%;
}
</style>