import { getPersonalInfo } from '@/api/home'
import { getToken, setToken, removeToken } from '@/utils/auth'
const state = {
  token: getToken(),
  name: '',
  avater: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
  org_name: ''
}
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avater) => {
    if (avater == '') {
      state.avater = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
    } else {
      state.avater = avater
    }
  },
  SET_ORG_NAME: (state, org_name) => {
    state.org_name = org_name
  },
}

const actions = {
  // get user info
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getPersonalInfo().then(response => {
        const { status, error_msg, data } = response
        if (status === false) {
          reject(error_msg)
        }
        if (!data) {
          reject('Verification failed, please Login again.')
        }
        const { member_name, head_url, member_id } = data.info;
        const { student_org } = data
        commit('SET_NAME', member_name);
        commit('SET_AVATAR', head_url);
        commit('SET_ORG_NAME', student_org);
        // commit('SET_IDENTYPE', identity_type);
        // commit('SET_LOGINID', id);
        // commit('SET_OID', oid);
        // commit('SET_GRADE', grade);
        // commit('SET_CARDNO', card_no);
        // commit('SET_CARDINFO', org);
        // commit('SET_VERIFIED', is_verified)

        // commit('INIT')
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
