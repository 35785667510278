import {
    Button, Input, Dropdown, DropdownMenu,
    DropdownItem, Progress, Pagination, Dialog, MessageBox,
    Message, Tooltip, Tabs, TabPane, Row, Col, Menu, Submenu, MenuItem,
    MenuItemGroup, Breadcrumb, BreadcrumbItem, Form, FormItem, Radio, RadioGroup,
    Upload, Loading, Select, Option, Checkbox, Avatar, Popover, Table, TableColumn, DatePicker,
    Backtop, Container, Header, Main, Footer
} from 'element-ui';

const element = {
    install: function (Vue) {
        Vue.use(Button)
        Vue.use(Input)
        Vue.use(Dropdown)
        Vue.use(DropdownMenu)
        Vue.use(DropdownItem)
        Vue.use(Progress)
        Vue.use(Pagination)
        Vue.use(Dialog)
        Vue.use(Tooltip)
        Vue.use(Tabs)
        Vue.use(TabPane)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Menu)
        Vue.use(Submenu)
        Vue.use(MenuItem)
        Vue.use(MenuItemGroup)
        Vue.use(Breadcrumb)
        Vue.use(BreadcrumbItem)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Radio)
        Vue.use(RadioGroup)
        Vue.use(Upload)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(Checkbox)
        Vue.use(Avatar)
        Vue.use(Popover)
        Vue.use(Table)
        Vue.use(TableColumn)
        Vue.use(DatePicker)
        Vue.use(Backtop)
        Vue.use(Container)
        Vue.use(Header)
        Vue.use(Main)
        Vue.use(Footer)

        Vue.use(Loading.directive)

        // Vue.prototype.$loading = Loading.service;
        Vue.prototype.$message = Message;
        Vue.prototype.$msgbox = MessageBox;
        Vue.prototype.$confirm = MessageBox.confirm;

    }

}

export default element